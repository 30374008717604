import { createBrowserRouter, redirect } from "react-router-dom";
import Onboarding from "./Onboarding";
import DashboardLayout from "./ClientApp";
import AdminDashboardLayout from "./AdminApp";

const router = createBrowserRouter([
  {
    path: "",
    loader: async () => {
      return redirect("onboarding/sign-in");
    },
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        lazy: async () => {
          let { Dashboard } = await import("./ClientApp/Dashboard/Dashboard");
          return { Component: Dashboard };
        },
      },
      {
        path: "loan",
        lazy: async () => {
          let { Loan } = await import("./ClientApp/Loan/Loan");
          return { Component: Loan };
        },
      },
      {
        path: "news",
        lazy: async () => {
          let { News } = await import("./ClientApp/News/News");
          return { Component: News };
        },
      },
      {
        path: "news/:id",
        lazy: async () => {
          let { NewsDetail } = await import("./ClientApp/News/NewsDetail");
          return { Component: NewsDetail };
        },
      },
      {
        path: "transaction-history",
        lazy: async () => {
          let { TransactionHistory } = await import("./ClientApp/TransactionHistory/TransactionHistory");
          return { Component: TransactionHistory };
        },
      },
      {
        path: "profile",
        lazy: async () => {
          let { Profile } = await import("./ClientApp/Profile/Profile");
          return { Component: Profile };
        },
      },
      {
        path: "settings",
        lazy: async () => {
          let { Settings } = await import("./ClientApp/Settings/Settings");
          return { Component: Settings };
        },
      },
    ],
  },
  {
    path: "admin/dashboard",
    element: <AdminDashboardLayout />,
    children: [
      {
        index: true,
        lazy: async () => {
          let { AdminDashboard } = await import("./AdminApp/Dashboard/AdminDashboard");
          return { Component: AdminDashboard };
        },
      },
      {
        path: "loan",
        lazy: async () => {
          let { AdminLoan } = await import("./AdminApp/Loan/Loan");
          return { Component: AdminLoan };
        },
      },
      {
        path: "users",
        lazy: async () => {
          let { AdminUsers } = await import("./AdminApp/Users/index");
          return { Component: AdminUsers };
        },
      },
      {
        path: "news",
        lazy: async () => {
          let { AdminNews } = await import("./AdminApp/News/AdminNews");
          return { Component: AdminNews };
        },
      },
      {
        path: "contributions",
        lazy: async () => {
          let { AdminContribution } = await import("./AdminApp/Contribution/Contribution");
          return { Component: AdminContribution };
        },
      },
      {
        path: "settings",
        lazy: async () => {
          let { AdminSettings } = await import("./AdminApp/AdminSettings/AdminSettings");
          return { Component: AdminSettings };
        },
      },
    ],
  },
  {
    path: "onboarding",
    element: <Onboarding />,
    children: [
      {
        path: "sign-in",
        lazy: async () => {
          let { ClientSignIn } = await import("./Onboarding/SignIn/ClientSignIn");
          return { Component: ClientSignIn };
        },
      },
      {
        path: "sign-up",
        lazy: async () => {
          let { SignUp } = await import("./Onboarding/SignUp");
          return { Component: SignUp };
        },
      },
      {
        path: "forgot-password",
        lazy: async () => {
          let { ForgotPassword } = await import("./Onboarding/ForgotPassword");
          return { Component: ForgotPassword };
        },
      },
    ],
  },
  {
    path: "admin/onboarding",
    element: <Onboarding />,
    children: [
      {
        path: "sign-in",
        lazy: async () => {
          let { AdminSignIn } = await import("./Onboarding/SignIn/AdminSignIn");
          return { Component: AdminSignIn };
        },
      },
    ],
  },
]);

export default router;
