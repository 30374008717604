import axios from "axios";
import { axiosInstanceAdmin, axiosInstanceClient } from "../../Services/config";

export const getReqClient = async ({ queryKey }) => {
  let { route, params } = queryKey[1];
  return axiosInstanceClient.get(`${route}`, { params }).then((res) => res.data);
};

export const getReqAdmin = async ({ queryKey }) => {
  let { route, params } = queryKey[1];
  return axiosInstanceAdmin.get(`${route}`, { params }).then((res) => res.data);
};

export const getPublicReq = async ({ queryKey }) => {
  let { route, params } = queryKey[1];
  return axios.get(`${route}`, { params }).then((res) => res.data);
};
