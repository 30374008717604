import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { GoHome } from "react-icons/go";
import { Avatar, Divider, Drawer } from "antd";
import { LuLogOut, LuUsers } from "react-icons/lu";
import { HiOutlineNewspaper } from "react-icons/hi2";
import { TbCurrencyNaira } from "react-icons/tb";
import { useState } from "react";
import { Confirm } from "notiflix";
import { PiPiggyBank } from "react-icons/pi";
import { IoSettingsOutline } from "react-icons/io5";
import Logo from "../Assets/Images/enrollment_logo.svg";
import AdminRouteGuard from "../Services/AdminRouteGuard";

const sidebar = [
  {
    title: "Dashboard",
    icon: <GoHome color="#667185" size="20px" />,
    active: <GoHome color="#E8562E" size="20px" />,
    route: "/admin/dashboard",
  },
  {
    title: "Loan",
    active: <TbCurrencyNaira color="#E8562E" size="20px" />,
    icon: <TbCurrencyNaira color="#667185" size="20px" />,
    route: "/admin/dashboard/loan",
  },
  {
    title: "News",
    active: <HiOutlineNewspaper color="#E8562E" size="20px" />,
    icon: <HiOutlineNewspaper color="#667185" size="20px" />,
    route: "/admin/dashboard/news",
  },
  {
    title: "Users",
    active: <LuUsers color="#E8562E" size="20px" />,
    icon: <LuUsers color="#667185" size="20px" />,
    route: "/admin/dashboard/users",
  },
  {
    title: "Contribution",
    active: <PiPiggyBank color="#E8562E" size="20px" />,
    icon: <PiPiggyBank color="#667185" size="20px" />,
    route: "/admin/dashboard/contributions",
  },
];

const sidebar2 = [
  {
    title: "Settings",
    active: <IoSettingsOutline color="#E8562E" size="20px" />,
    icon: <IoSettingsOutline color="#667185" size="20px" />,
    route: "/admin/dashboard/settings?tab=staff",
  },
];

const AdminDashboardLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("admin_user"));

  return (
    <AdminRouteGuard>
      <div className="flex">
        <div
          className="hidden w-[300px] border-r border-r-[#E4E7EC] h-[100vh] lg:flex justify-between p-2"
          style={{ flexDirection: "column" }}
        >
          <div>
            <NavLink to="/">
              <img src={Logo} className="h-[74px] m-4" alt="" />
            </NavLink>
            <div className="mt-[2rem]">
              {sidebar.map((item, index) => (
                <NavLink
                  end
                  key={index}
                  to={item.route}
                  className={({ isActive }) =>
                    isActive
                      ? "bg-[#FFECE5] py-3 px-5 text-[14px] text-[#101928] font-medium flex item-center gap-4 rounded-[4px] leading-[20.3px]"
                      : "py-2 px-5 text-[#344054] flex items-center gap-4 leading-[20.3px] text-[14px] my-3"
                  }
                >
                  {location.pathname === item.route ? item.active : item.icon}
                  {item.title}
                </NavLink>
              ))}
              <Divider />
            </div>
          </div>
          <div className="mt-[3rem]">
            {sidebar2.map((item, index) => (
              <NavLink
                key={index}
                to={item.route}
                className={({ isActive }) =>
                  isActive
                    ? "bg-[#FFECE5] py-3 px-5 text-[14px] text-[#101928] font-medium flex item-center gap-4 rounded-[4px] leading-[20.3px]"
                    : "py-2 px-5 text-[#344054] flex items-center gap-4 leading-[20.3px] text-[14px] my-3"
                }
              >
                {location.pathname === item.route ? item.active : item.icon}
                {item.title}
              </NavLink>
            ))}
            <div className="flex justify-between px-3 items-center my-5">
              <div className="flex gap-3">
                <Avatar size="large">{user?.name[0]}</Avatar>
                <div>
                  <h4 className="text-[14px] text-[#101928] font-semibold">
                    {user?.name.length > 25 ? `${user?.name.slice(0, 25)}...` : user?.name}
                  </h4>
                  <h6 className="font-inter text-[#475367] text-[14px]">
                    {user?.email.length > 25 ? `${user?.email.slice(0, 25)}...` : user?.email}
                  </h6>
                </div>
              </div>
              <LuLogOut
                size="25px"
                className="cursor-pointer"
                onClick={() =>
                  Confirm.show(
                    "Logout",
                    "Are you sure, you want to Logout?",
                    "Yes",
                    "No",
                    () => {
                      localStorage.clear();
                      navigate("/admin/onboarding/sign-in");
                    },
                    () => {},
                    {
                      titleColor: "#EB5017",
                      okButtonColor: "#FFFFFF",
                      okButtonBackground: "#EB5017",
                      backOverlayColor: "rgba(0,0,0,0.7)",
                    }
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="content">
          <Outlet />
        </div>

        {/* Mobile Navigation */}
        <Drawer
          title=""
          key="left"
          open={open}
          width="80%"
          closable={false}
          placement="left"
          onClose={() => setOpen(!open)}
        >
          <div className="h-[93vh] flex justify-between p-2" style={{ flexDirection: "column" }}>
            <div>
              <NavLink to="/">
                <img src={Logo} className="h-[74px] m-4" alt="" />
              </NavLink>
              <div className="mt-[2rem]">
                {sidebar.map((item, index) => (
                  <NavLink
                    end
                    key={index}
                    to={item.route}
                    onClick={() => setOpen(!open)}
                    className={({ isActive }) =>
                      isActive
                        ? "bg-[#FFECE5] py-3 px-5 text-[14px] text-[#101928] font-medium flex item-center gap-4 rounded-[4px] leading-[20.3px]"
                        : "py-2 px-5 text-[#344054] flex items-center gap-4 leading-[20.3px] text-[14px] my-3"
                    }
                  >
                    {location.pathname === item.route ? item.active : item.icon}
                    {item.title}
                  </NavLink>
                ))}
                <Divider />
              </div>
            </div>
            <div className="mt-[3rem]">
              {sidebar2.map((item, index) => (
                <NavLink
                  key={index}
                  to={item.route}
                  className={({ isActive }) =>
                    isActive
                      ? "bg-[#FFECE5] py-3 px-5 text-[14px] text-[#101928] font-medium flex item-center gap-4 rounded-[4px] leading-[20.3px]"
                      : "py-2 px-5 text-[#344054] flex items-center gap-4 leading-[20.3px] text-[14px] my-3"
                  }
                >
                  {location.pathname === item.route ? item.active : item.icon}
                  {item.title}
                </NavLink>
              ))}
              <div className="flex px-3 items-center gap-4 my-5">
                <Avatar size="large">{user?.name[0]}</Avatar>
                <div>
                  <h4 className="text-[14px] text-[#101928] font-semibold">
                    {user?.name.length > 25 ? `${user?.name.slice(0, 25)}...` : user?.name}
                  </h4>
                  <h6 className="font-inter text-[#475367] text-[14px]">
                    {user?.email.length > 25 ? `${user?.email.slice(0, 25)}...` : user?.email}
                  </h6>
                </div>
                <LuLogOut
                  size="20px"
                  className="cursor-pointer"
                  onClick={() =>
                    Confirm.show(
                      "Logout",
                      "Are you sure, you want to Logout?",
                      "Yes",
                      "No",
                      () => {
                        localStorage.clear();
                        navigate("/admin/onboarding/sign-in");
                      },
                      () => {},
                      {
                        titleColor: "#EB5017",
                        okButtonColor: "#FFFFFF",
                        okButtonBackground: "#EB5017",
                        backOverlayColor: "rgba(0,0,0,0.7)",
                      }
                    )
                  }
                />
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </AdminRouteGuard>
  );
};

export default AdminDashboardLayout;
