import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { GoHome } from "react-icons/go";
import { IoSettingsOutline } from "react-icons/io5";
import { Avatar, Divider, Drawer, Image } from "antd";
import { LuLogOut } from "react-icons/lu";
import { HiOutlineNewspaper } from "react-icons/hi2";
import { TbCurrencyNaira } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getReqClient } from "../Hooks/Query/index";
import { Confirm } from "notiflix";
import Logo from "../Assets/Images/enrollment_logo.svg";
import ClientRouteGuard from "../Services/ClientRouteGuard";

const sidebar = [
  {
    title: "Dashboard",
    icon: <GoHome color="#667185" size="20px" />,
    active: <GoHome color="#E8562E" size="20px" />,
    route: "/dashboard",
  },
  {
    title: "Loan",
    active: <TbCurrencyNaira color="#E8562E" size="20px" />,
    icon: <TbCurrencyNaira color="#667185" size="20px" />,
    route: "/dashboard/loan",
  },
  {
    title: "News",
    active: <HiOutlineNewspaper color="#E8562E" size="20px" />,
    icon: <HiOutlineNewspaper color="#667185" size="20px" />,
    route: "/dashboard/news",
  },
  {
    title: "Transaction History",
    active: <TbFileInvoice color="#E8562E" size="20px" />,
    icon: <TbFileInvoice color="#667185" size="20px" />,
    route: "/dashboard/transaction-history",
  },
];

const sidebar2 = [
  {
    title: "Settings",
    active: <IoSettingsOutline color="#E8562E" size="20px" />,
    icon: <IoSettingsOutline color="#667185" size="20px" />,
    route: "/dashboard/settings?tab=account-settings",
  },
  // {
  //   title: "Help Center",
  //   active: <PiHeadsetDuotone color="#E8562E" size="20px" />,
  //   icon: <PiHeadsetDuotone color="#667185" size="20px" />,
  //   route: "/dashboard/help-center",
  // },
];

const DashboardLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  // Queries & Mutation
  const { data: user, isSuccess } = useQuery({
    queryKey: ["get-user-profile", { route: `users/profile`, params: {} }],
    queryFn: getReqClient,
  });

  isSuccess && localStorage.setItem("user", JSON.stringify(user.data));

  return (
    <ClientRouteGuard>
      <div className="flex">
        <div
          className="hidden w-[300px] border-r border-r-[#E4E7EC] h-[100vh] lg:flex justify-between p-2"
          style={{ flexDirection: "column" }}
        >
          <div>
            <NavLink to="/">
              <img src={Logo} className="h-[74px] m-4" alt="" />
            </NavLink>
            {window.location.pathname === "/dashboard/profile" && (
              <div className="h-[100vh] absolute top-0 left-0 w-[300px] z-10"></div>
            )}
            <div className="mt-[2rem]">
              {sidebar.map((item, index) => (
                <NavLink
                  end
                  key={index}
                  to={item.route}
                  className={({ isActive }) =>
                    isActive
                      ? "bg-[#FFECE5] py-3 px-5 text-[14px] text-[#101928] font-medium flex item-center gap-4 rounded-[4px] leading-[20.3px]"
                      : "py-2 px-5 text-[#344054] flex items-center gap-4 leading-[20.3px] text-[14px] my-3"
                  }
                >
                  {location.pathname === item.route ? item.active : item.icon}
                  {item.title}
                </NavLink>
              ))}
              <Divider />
            </div>
          </div>
          <div className="mt-[3rem]">
            {sidebar2.map((item, index) => (
              <NavLink
                key={index}
                to={item.route}
                className={({ isActive }) =>
                  isActive
                    ? "bg-[#FFECE5] py-3 px-5 text-[14px] text-[#101928] font-medium flex item-center gap-4 rounded-[4px] leading-[20.3px]"
                    : "py-2 px-5 text-[#344054] flex items-center gap-4 leading-[20.3px] text-[14px] my-3"
                }
              >
                {location.pathname === item.route ? item.active : item.icon}
                {item.title}
              </NavLink>
            ))}
            {user?.data?.first_name ? (
              <div className="flex px-3 items-center justify-between my-5">
                <div className="flex gap-3">
                  <Image
                    width={40}
                    src={user?.data?.profile_picture_path}
                    className="h-[40px] w-[40px] rounded-[50%]"
                    alt=""
                  />
                  <div>
                    <h4 className="text-[14px] text-[#101928] font-semibold">
                      {user?.data?.first_name} {user?.data?.last_name}
                    </h4>
                    <h6 className="font-inter text-[#475367] text-[12px]">
                      {user?.data?.email.length > 25 ? `${user?.data?.email.slice(0, 25)}...` : user?.data?.email}
                    </h6>
                  </div>
                </div>
                <LuLogOut
                  size="20px"
                  className="cursor-pointer"
                  onClick={() =>
                    Confirm.show(
                      "Logout",
                      "Are you sure, you want to Logout?",
                      "Yes",
                      "No",
                      () => {
                        localStorage.clear();
                        navigate("/onboarding/sign-in");
                      },
                      () => {},
                      {
                        titleColor: "#EB5017",
                        okButtonColor: "#FFFFFF",
                        okButtonBackground: "#EB5017",
                        backOverlayColor: "rgba(0,0,0,0.7)",
                      }
                    )
                  }
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="content">
          <Outlet />
        </div>

        {/* Mobile Navigation */}
        <Drawer
          title=""
          key="left"
          open={open}
          width="80%"
          placement="left"
          closable={false}
          onClose={() => setOpen(!open)}
        >
          {window.location.pathname === "/dashboard/profile" && (
            <div className="h-[100vh] absolute top-0 left-0 w-[300px] z-10"></div>
          )}
          <div className="h-[93vh] flex justify-between p-2" style={{ flexDirection: "column" }}>
            <div>
              <NavLink to="/">
                <img src={Logo} className="h-[74px] m-4" alt="" />
              </NavLink>
              <div className="mt-[2rem]">
                {sidebar.map((item, index) => (
                  <NavLink
                    end
                    key={index}
                    to={item.route}
                    onClick={() => setOpen(!open)}
                    className={({ isActive }) =>
                      isActive
                        ? "bg-[#FFECE5] py-3 px-5 text-[14px] text-[#101928] font-medium flex item-center gap-4 rounded-[4px] leading-[20.3px]"
                        : "py-2 px-5 text-[#344054] flex items-center gap-4 leading-[20.3px] text-[14px] my-3"
                    }
                  >
                    {location.pathname === item.route ? item.active : item.icon}
                    {item.title}
                  </NavLink>
                ))}
                <Divider />
              </div>
            </div>
            <div className="mt-[3rem]">
              {sidebar2.map((item, index) => (
                <NavLink
                  key={index}
                  to={item.route}
                  onClick={() => setOpen(!open)}
                  className={({ isActive }) =>
                    isActive
                      ? "bg-[#FFECE5] py-3 px-5 text-[14px] text-[#101928] font-medium flex item-center gap-4 rounded-[4px] leading-[20.3px]"
                      : "py-2 px-5 text-[#344054] flex items-center gap-4 leading-[20.3px] text-[14px] my-3"
                  }
                >
                  {location.pathname === item.route ? item.active : item.icon}
                  {item.title}
                </NavLink>
              ))}
              <div className="flex px-3 items-center gap-4 my-5 pt-4">
                <Avatar size="large">A</Avatar>
                <div>
                  <h4 className="text-[14px] text-[#101928] font-semibold">
                    {user?.data?.first_name} {user?.data?.last_name}
                  </h4>
                  <h6 className="font-inter text-[#475367] text-[12px]">
                    {user?.data?.email.length > 25 ? `${user?.data?.email.slice(0, 25)}...` : user?.data?.email}
                  </h6>
                </div>
                <LuLogOut
                  size="20px"
                  className="cursor-pointer"
                  onClick={() =>
                    Confirm.show(
                      "Logout",
                      "Are you sure, you want to Logout?",
                      "Yes",
                      "No",
                      () => {
                        localStorage.clear();
                        navigate("/onboarding/sign-in");
                      },
                      () => {},
                      {
                        titleColor: "#EB5017",
                        okButtonColor: "#FFFFFF",
                        okButtonBackground: "#EB5017",
                        backOverlayColor: "rgba(0,0,0,0.7)",
                      }
                    )
                  }
                />
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </ClientRouteGuard>
  );
};

export default DashboardLayout;
