import { Notify } from "notiflix";
import axios from "axios";
import queryString from "query-string";

const axiosInstanceClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  paramsSerializer(params) {
    return queryString.stringify(params, { indices: false });
  },
});

// Request Interceptors
axiosInstanceClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("client_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptors
axiosInstanceClient.interceptors.response.use(
  (response) => {
    //success status
    if (response.status === 200) {
      if (response.config.method !== "get") {
        Notify.success(response.data.message, {
          ID: "MKA",
          timeout: 5000,
          useIcon: false,
          width: "400px",
          fontSize: "14px",
          position: "right-top",
          className: "h-[50px]",
          messageMaxLength: 500,
          showOnlyTheLastOne: true,
          success: {
            textColor: "#026e0d",
            background: "#e9fbec",
          },
        });
      }
      return response;
    } else {
      const messages = response.data.messages;
      if (messages) {
        if (messages instanceof Array) {
          return Promise.reject({ messages });
        }
        return Promise.reject({ messages: [messages] });
      }
      return Promise.reject({ messages: ["got errors"] });
    }
  },
  (error) => {
    //unauthorised error
    Notify.failure(error.response.data.message, {
      ID: "MKA",
      timeout: 8000,
      useIcon: false,
      width: "400px",
      fontSize: "14px",
      position: "right-top",
      className: "h-[50px]",
      messageMaxLength: 500,
      showOnlyTheLastOne: true,
      backOverlayColor: "rgba(0,0,0,0.5)",
      failure: {
        textColor: "#e40909",
        background: "#ffece5",
      },
    });
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location = "/onboarding/sign-in";
    }
    //internal server error
    else if (error.response && error.response.status === 500) {
      return Promise.reject(error.response);
    }
    //any other error
    else return Promise.reject(error);
  }
);

const axiosInstanceAdmin = axios.create({
  baseURL: process.env.REACT_APP_BASE_ADMIN_URL,
  paramsSerializer(params) {
    return queryString.stringify(params, { indices: false });
  },
});

// Admin Request Interceptors
axiosInstanceAdmin.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Admin Response Interceptors
axiosInstanceAdmin.interceptors.response.use(
  (response) => {
    //success status
    if (response.status === 200) {
      if (response.config.method !== "get") {
        Notify.success(response.data.message, {
          ID: "MKA",
          timeout: 5000,
          useIcon: false,
          width: "400px",
          fontSize: "14px",
          position: "right-top",
          className: "h-[50px]",
          messageMaxLength: 500,
          showOnlyTheLastOne: true,
          success: {
            textColor: "#026e0d",
            background: "#e9fbec",
          },
        });
      }
      return response;
    } else {
      const messages = response.data.messages;
      if (messages) {
        if (messages instanceof Array) {
          return Promise.reject({ messages });
        }
        return Promise.reject({ messages: [messages] });
      }
      return Promise.reject({ messages: ["got errors"] });
    }
  },
  (error) => {
    //unauthorised error
    Notify.failure(error.response.data.message, {
      ID: "MKA",
      timeout: 8000,
      useIcon: false,
      width: "400px",
      fontSize: "14px",
      position: "right-top",
      className: "h-[50px]",
      messageMaxLength: 500,
      showOnlyTheLastOne: true,
      backOverlayColor: "rgba(0,0,0,0.5)",
      failure: {
        textColor: "#e40909",
        background: "#ffece5",
      },
    });
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location = "/onboarding/sign-in";
    }
    //internal server error
    else if (error.response && error.response.status === 500) {
      return Promise.reject(error.response);
    }
    //any other error
    else return Promise.reject(error);
  }
);

export { axiosInstanceClient, axiosInstanceAdmin };
