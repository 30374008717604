import { Outlet } from "react-router-dom";
import OnboardingBg from "../Assets/Images/onboarding_bg.png";

const Onboarding = () => {
  return (
    <div className="h-[100vh] w-[100vw] px-[1rem]">
      <div className="block lg:flex items-center">
        <div className="block mt-[2rem] lg:mt-0 lg:flex items-center justify-center lg:h-[100vh] lg:w-[50vw]">
          <Outlet />
        </div>
        <div className="mt-[2.5rem] lg:w-[50vw] lg:mt-0">
          <div className="bg-white pb-[1rem] lg:h-[100vh]  lg:p-[1rem]">
            <div className="bg-white relative h-[230px] lg:h-[100%] w-[100%] flex items-center justify-center rounded-[30px] overflow-hidden">
              <img src={OnboardingBg} className="w-[100%] lg:block" alt="" />
              <div className="absolute px-[1rem] lg:px-[2rem] max-w-[602px] text-center lg:text-left">
                <h1 className="text-[24px] lg:text-[60px] text-secondary font-semibold">
                  Join Us in Building a Better Future Together!"
                </h1>
                <p className="text-[#E4DBDB] text-[16px] lg:text-[18px] mt-[1.5rem] max-w-[420px]">
                  Welcome to Our Cooperative Community: Discover the Power of Collaboration and Shared Ownership for
                  Positive Change.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
